import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { ActivatedRoute, Router, Params, RouterStateSnapshot} from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qb-callback',
  templateUrl: './qb-callback.component.html',
})
export class QBCallback implements OnInit {

	public status = 0
	
	constructor(public global: GlobalService, public modalService: NgbModal, public coinService:CoinService, private router:Router, public route:ActivatedRoute) {
	}

	ngOnInit(): void {
		window.scroll(0, 0);

		
		this.status = 0
		if(this.global.isAuthenticated()){

			this.route.queryParams.subscribe(params => {
				this.doAuthentication(params)
			});
		} else {
			this.global._cookieService.delete('oauth2Data', '/')
			this.global.logoutFinishStage()
		}
		
	}

	public showError = false
	doAuthentication(params){
		let oauth2data = this.global._cookieService.get('oauth2Data')
		console.log('cookie')
		console.log(oauth2data)
		this.global.connectionDisplay = 6
		try {
			
			oauth2data = JSON.parse(oauth2data)
			oauth2data['params'] = params
		} catch (error) {
			
			this.showError = true
			setTimeout( () => { 
				this.router.navigate(['entity', oauth2data['portfolio_id'], 'connections'])
			}, 5000 );
		}

		console.log('state')
		console.log(params['state'])

		console.log('key')
		console.log(oauth2data['key'])

		if(oauth2data['key'] == params['state']){
			console.log('match')
			this.global.httpPOST('qb_oauth2_connect', oauth2data).subscribe(response => {
				if(response.status==true){
					this.global.addedoAuth2Exchange = true
					this.global.connectionDisplay = 6

					setTimeout( () => { 
						if(response.data == false){
							this.showError = true
						} else {

							this.global._cookieService.delete('oauth2Data', '/')
							this.router.navigate(['entity', oauth2data['portfolio_id'], 'connections']);
							if(!this.global.keepUserLoggedIn){
								this.global.cookieUnsetForOAuth2ExchangeCons()
							}
						}
					}, 5000 );
					
					
				} 
			}, error => {
			});
		} else {
			console.log('not a match')
			if(!this.global.keepUserLoggedIn){
				this.global.cookieUnsetForOAuth2ExchangeCons()
			}
			console.log('error')
		}
		
	}

	


}
