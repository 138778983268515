
<section style="background:#fff">
	<div class="container">
			<div class="pricing-header px-3 py-3 pt-md-5 mx-auto text-center">

				<div class="row d-flex align-items-center">
					<div class="col-md-12 text-md-left mb-4 mb-md-0">
						<div class="text-center" >
							<a href="#" [routerLink]="['/']">
								<img src='{{global.FE_IMG_URL}}img/logo_inv.png' width="75" height="75" class="d-inline-block align-top text-center" alt="">
							</a>
						</div>
						<br/>
						<h1 class="text-center" >Welcome back {{ global._userDetails.first_name }}!</h1>
						<p class="text-center"> You cancelled the package but your data is not lost. You can restart your plan anytime. Please enter the payment details and get started.  </p>
					</div>
				</div>
			</div>

	</div>
</section>
<section style="background:#fff;">
	<div class="container">		
		<div class="row">
			<div class="col-md-6" (keyup.enter)="purchaseCheck()">
				<div class="card buy-box">
					<div class="">
						<div *ngIf="errorStatus==202" class="alert alert-danger" role="alert">
							Please fill all the details in the form below.
						</div>
						<h5>1. Choose billing type</h5>
						<div class="row" *ngIf="currentPackage!=null">
							<div class="col-md-6">
								<div class="card flex-md-row box-shadow h-md-250 pointer" [ngClass]="{'card-selected': restartMemberShipDetails['billingType']=='yearly'}" (click)="restartMemberShipDetails['billingType']='yearly'">
									<div class="card-body d-flex flex-column align-items-start">
										<div class="form-check-inline text-center pointer">
											<label class="form-check-label pointer">
												<input type="radio" class="form-check-input pointer" name="optradio" [value]="'yearly'" [(ngModel)]="restartMemberShipDetails['billingType']" > 
												Bill yearly
											<span class="fade-in text-success">
												(save {{ 100 - ((currentPackage.policies.billing.anually * 12)/(currentPackage.policies.billing.monthly * 12) *100) }}% )
											</span>
											</label>
										</div>
										<p class="card-text mb-auto">
											${{ currentPackage.policies.billing.anually }} / month <br/>
											<b>${{ currentPackage.policies.billing.anually * 12 }} charged yearly</b>
										</p>
										
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="card flex-md-row box-shadow h-md-250 pointer" [ngClass]="{'card-selected': restartMemberShipDetails['billingType']=='monthly'}" (click)="restartMemberShipDetails['billingType']='monthly'">
									<div class="card-body d-flex flex-column align-items-start">
										<div class="form-check-inline text-center pointer">
											<label class="form-check-label pointer">
												<input type="radio" class="form-check-input pointer" name="optradio" [value]="'monthly'" [(ngModel)]="restartMemberShipDetails['billingType']" > 
												Bill monthly
											</label>
										</div>
										<p class="card-text mb-auto">
											${{ currentPackage.policies.billing.monthly }} /  month<br/>
											<b>${{ currentPackage.policies.billing.monthly }} charged monthly</b>
										</p>
									</div>
								</div>
							</div>
						</div>
						<hr/>
						<h5>3. Enter payment details</h5>
						
						<div id="card-element" class="field"></div>
							  
						<br/>
						<br/>
						<div class="row">
							<div class="col-md-12">
								<button type="submit" type="button" class="btn btn-primary btn-lg btn-block" *ngIf="formState==0" (click)="purchaseCheck()">Complete Purchase</button>
								<button type="button" class="btn btn-primary btn-lg btn-block disabled" *ngIf="formState==1">Purchasing...</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row ">
					<div class="col-md-8 buy-box" *ngIf="currentPackage!=null">
							<div class="row ">
								<div class="col-md-12">
									<p><b> Order Summary</b> </p>
								</div>
							</div>
							<div class="row border-bottom">
								<div class="col-md-6">
									<b>Item</b>
								</div>
								<div class="col-md-6">
									<b>Amount</b>
								</div>
							</div>
							<br/>
							<div class="row">
								<div class="col-md-6">
									<b>{{currentPackage.name}}</b> Package
								</div>
								<div class="col-md-6">
									<span *ngIf="restartMemberShipDetails['billingType']=='yearly'">${{ currentPackage.policies.billing.anually * 12 }} / year </span>
									<span *ngIf="restartMemberShipDetails['billingType']=='monthly'">${{ currentPackage.policies.billing.monthly }} / month </span>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<span *ngIf="restartMemberShipDetails['billingType']=='yearly'" class="fade-in text-success">
										Save ${{(currentPackage.policies.billing.monthly * 12) - (currentPackage.policies.billing.anually * 12)}} 
										({{ 100 - ((currentPackage.policies.billing.anually * 12)/(currentPackage.policies.billing.monthly * 12) *100) }}%) per year.
									</span>
								</div>
							</div>
					</div>
				</div>
				<br/>
				<div class="row" *ngIf="currentPackage!=null">
					<div class="col-md-12">
						<p><b>{{currentPackage.name}}</b> Package</p>
						<div class="card-deck mb-3 text-center">
							<div class="card card-features  mb-3 box-shadow" >
								<ul class="list-group">
				
									<li class="list-group-item list-group-item-action" *ngIf="currentPackage.policies.business.status=='true'">
										<i class="fa fa-check" aria-hidden="true"></i><span > Company Account</span>
									</li>
									<li class="list-group-item list-group-item-action" *ngIf="currentPackage.policies.business.status=='true'">
										<i class="fa fa-check" aria-hidden="true"></i> Upto {{currentPackage.policies.business.members}} employee accounts
									</li>
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true"></i>
										<span *ngIf="currentPackage.policies.features.portfolios!=-1">
											{{currentPackage.policies.features.portfolios}}
											<span *ngIf="currentPackage.policies.features.portfolios==1">Entity</span>
											<span *ngIf="currentPackage.policies.features.portfolios!=1">Entities</span>
										</span>
										<span *ngIf="currentPackage.policies.features.portfolios==-1">Unlimited Entities</span>
										
									</li>
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true"></i>
										<span *ngIf="currentPackage.policies.features.exchanges!=-1">{{currentPackage.policies.features.exchanges}}</span>
										<span *ngIf="currentPackage.policies.features.exchanges==-1">Unlimited</span>
										Exchanges
									</li >
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true" ></i>
										<span> Unlimited AUM</span>
									</li>
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true" ></i>
										<span> Unlimited Transaction Storage</span>
									</li>
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true" ></i>
										<span> Transaction Labelling</span>
									</li>
									<li class="list-group-item list-group-item-action">
										<i class="fa fa-check" aria-hidden="true" ></i>
										<span> Entity Analytics</span>
									</li>
									<li class="list-group-item list-group-item-action" *ngIf="currentPackage.policies.features.CSV=='true'">
										<i class="fa fa-check" aria-hidden="true"></i><span > CSV/PDF Export </span>
									</li>
									<li class="list-group-item list-group-item-action" >
										<i class="fa fa-check" aria-hidden="true"></i><span > {{currentPackage.policies.support}} Support </span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div class="row">
					<div class="col-md-8">
						<span><b>Accepted Payments</b></span>
						<br/>
						<img src="{{global.FE_IMG_URL}}img/payment/amex.png" width="40" height="28" class="d-inline-block align-top" alt="amex"> 
						<img src="{{global.FE_IMG_URL}}img/payment/visa.png" width="40" height="28" class="d-inline-block align-top" alt="visa"> 
						<img src="{{global.FE_IMG_URL}}img/payment/master.png" width="40" height="28" class="d-inline-block align-top" alt="mastercard">  <br/><br/>
						
					</div>
				</div>
				
					

					
			</div>
		</div>
	</div>

</section>	

<ng-template #paymentProcessingModal let-c="close" let-d="dismiss">
	<div class="modal-body">
		<div class="row" *ngIf="divState==1">
			<br/>
			<div class="col-md-12 text-center">
				<div class="row text-center">
					<div class="col">
						<img class="card-icons" src="{{global.FE_IMG_URL}}img/payment/loader.gif"  height="50x" width="50"> 
					</div>
				</div>
				<div class="row text-center">
					<div class="col">
						<h1 >
							<span><b> Processing Payment </b></span>
						</h1>
						<p text-center>
							<span>Give us a moment while we process your payment securely...</span>
						</p>
					</div>
				</div>
			</div>
		</div><div class="row" *ngIf="divState==3">
				<br/>
				<div class="col-md-12 text-center">
					<div class="row text-center">
						<div class="col">
							<i class="fa fa-check text-success icon-lg" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row text-center">
						<div class="col">
							<h1 >
								<span><b> Payment Successful </b></span>
							</h1>
							<p text-center>
								<span> 
									Your transaction is complete. We are initiating your account. You will be redirected to your account shortly.
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		<div class="row" *ngIf="divState==2">
			<br/>
			<div class="col-md-12 text-center">
				<div class="row text-center">
					<div class="col">
						<i class="fa fa-exclamation-circle text-danger icon-lg" aria-hidden="true"></i>
					</div>
				</div>
				<div class="row text-center">
					<div class="col">
						<h1 >
							<span><b> Payment Failed </b></span>
						</h1>
						<p text-center>
							<span> We attempted to charge your card but it was not successful. 
								<br/><br/>
								<div class="buy-box">
									<p><b>{{payErrorMessgae}}</b></p>
								</div>
								<br/>
								If this problem persists, please <a href="#!" [routerLink]="['/contact']" target="_blank">contact</a>  our support team.
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer" *ngIf="divState==2">
		<button type="button" class="btn btn-primary btn-lg " (click)="d('Cross click');">Close</button>
	</div>
</ng-template>
		