import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { PolicyService } from '../../_services/policy.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forEach } from '@angular/router/src/utils/collection';
import { RestartMembershipComponent } from 'app/_login-page/restart-membership/restart-membership.component';

import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

const addressDetect = require('cryptocurrency-address-detector');

@Component({
	selector: 'app-create-contact',
	templateUrl: './create-contact.component.html'
})

export class CreateContactComponent implements OnInit {

	@Input() createDisplayMode: any = 0;
	@Input() addressBookModState: any = 0;
	@Input() viewingAddress: any = null;
	@Input() newAddressDetails: any = null;
	@Input() cryptoInfo: any = null;
	@Input() disabled: any = false;
	@Input() allowCryptoSelect: any = true;


	@Output() addressCreated: any = new EventEmitter();
	@Output() addressAdded: any = new EventEmitter();

	objectKeys = Object.keys

	public modalRef

	public addressDetails = {
		id: 0,
		type: 1,
		groupIds: [],
		portfolioId: null,
		portfolioName: null
	}


	public addressGroups = []

	public addressGroupDetails = {
		name: '',
		desc: '',
		members: [], //Address Ids
		type: 1,
		portfolioId: null,
		portfolioName: null
	}

	public additionalAddress = {
		label: null,
		address: null,
	}

	public importAddress = {
		uploadEvent: null,
		importState: 0,
		walletsImported: false,
		importFileTypeError: null,
		errorList: []
	};

	public importHistory = null
	public exportHistory = null
	public dataLoading = false;
	public showLoadMore = false;
	public rollbackState = 0;
	public paginatecount = 100;



	public addressValidation = null
	public errorCode = null

	public addingAddress = false
	public editingAddress = false

	public addingGroup = false
	public editingGroup = false
	public addingGroupReq = true

	public dataloading = false
	public selectedAddress = null

	public currentAddressViewIndex = null

	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});

	constructor(public global: GlobalService, public coinService: CoinService, public policyService: PolicyService, public modalService: NgbModal) {

	}

	ngOnInit() {
		//this.viewingAddress = null
		this.newAddressDetails = null

		this.addressDetails = {
			id: 0,
			type: this.global.currentPortfolioId ? 3 : 1,
			groupIds: [],
			portfolioId: this.global.currentPortfolioId ? this.global.currentPortfolioId : null,
			portfolioName: this.global.currentPortfolio ? this.global.currentPortfolio.name : null
		}

		this.addressGroupDetails = {
			name: '',
			desc: '',
			members: [], //Address Ids
			type: this.global.currentPortfolioId ? 3 : 1,
			portfolioId: this.global.currentPortfolioId ? this.global.currentPortfolioId : null,
			portfolioName: this.global.currentPortfolio ? this.global.currentPortfolio.name : null
		}
	
		// console.log(this.viewingAddress)
		// console.log(this.newAddressDetails)

		this.getAddressGroups()
		this.getUserPortfolios()
		// console.log(this.addressGroups)
	}

	// no longer used 
	checkAddress(address) {

		if (address != null) {
			let crypto = null
			let splitted = null
			addressDetect(address).then(cryptocurrency => {
				crypto = cryptocurrency
				// console.log('detected crypto: ' + crypto)

				if (cryptocurrency == 'Cryptocurrency could not be detected') {
					this.addressValidation = 0
				} else {

					splitted = crypto.split("/", 3);
					// console.log('detected crypto: ' + splitted)

					if (this.addressDetails['crypto'] != null || this.addressDetails['crypto'] != '') {
						if (splitted.length > 1) {
							if (
								splitted[0] == this.addressDetails['crypto']['symbol'] ||
								splitted[1] == this.addressDetails['crypto']['symbol'] ||
								splitted[2] == this.addressDetails['crypto']['symbol']
							) {
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						} else {
							if (splitted == this.addressDetails['crypto']['symbol']) {
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						}
					}
				}
			});
		}
	}

	viewAddress(address) {
		this.viewingAddress = address
	}

	createAddress() {
		this.addingAddress = true
		this.addressDetails.groupIds = this.loadGroupIds()
		if (this.validateAddressForm()) {
			this.global.httpPOST('add_address', { addressDetails: this.addressDetails, verified: this.addressValidation }).subscribe(response => {

				if (response.status == true) {
					this.global.getAllAddresses()
					setTimeout(() => {
						this.resetAdding()
						this.global.getAllAddresses()
						this.global.selectedAddressGroup(true)
						this.modalRef.close();
						this.addingAddress = false
						this.addressCreated.emit(true)
					}, 2000);

				} else {
					this.addingAddress = false
					this.errorCode = 6
				}
			}, error => {
				this.addingAddress = false
			});
		} else {
			this.addingAddress = false
		}
	}

	setCoinTypeId(crypto) {
		this.addressDetails['crypto'] = crypto
		if (this.addressDetails['address'] != null || this.addressDetails['address'] != '') {
			this.checkAddress(this.addressDetails['address'])
		}
	}

	setCoinTypeIdForAddress(crypto) {
		this.additionalAddress['cryptoId'] = crypto['id']
	}

	resetEditAddressCoin() {
		this.addressDetails['crypto_id'] = null
	}

	validateAddressForm() {
		if (this.addressDetails['name'] == null || this.addressDetails['name'] == '') {
			this.errorCode = 1
			return false
		}

		// if(this.addressDetails['crypto']==null || this.addressDetails['crypto'] == ''){
		// 	this.errorCode = 2
		// 	return false
		// }

		if (this.addressDetails['address'] == null || this.addressDetails['address'] == '') {
			this.errorCode = 3
			return false
		}

		if (this.addressDetails['type'] == null) {
			this.errorCode = 4
			return false
		}

		if(this.addressDetails['type'] == 3 && (this.addressDetails['portfolioId'] == null || !this.addressDetails['portfolioId'])){
			this.errorCode = 9
			return false
		}
		// removing address validation
		// if(this.addressValidation == 2){
		// 	this.errorCode = 5
		// 	return false
		// }

		if (this.phoneForm['controls']['phone']['value'] != null && !this.phoneForm.valid) {
			//Invalid Phone Number
			this.errorCode = 7;
			return false
		} else {
			if (this.phoneForm['controls']['phone']['value'] != null) {
				this.addressDetails['tel'] = this.phoneForm['controls']['phone']['value']['internationalNumber'];
			}
		}

		if (this.addressDetails['email'] != null && !this.global.validateEmail(this.addressDetails['email'])) {
			this.errorCode = 8;
			return false
		}



		return true;
	}

	saveAddressChanges() {
		this.editingAddress = true
		this.addressDetails.groupIds = this.loadGroupIds()
		this.global.httpPOST('edit_address', { addressDetails: this.addressDetails }).subscribe(response => {
			this.editingAddress = false
			if (response.status == true) {
				this.resetAdding()
			}
			this.global.getAllAddresses()
			this.global.selectedAddressGroup(true)
			this.modalRef.close();

		}, error => {
			this.editingAddress = false
		});
	}

	triggetAddressEditing(content) {
		this.addressDetails = this.viewingAddress
		this.addressBookModState = 1
		this.openModal(content)
	}

	triggerAddressAdding(content) {
		this.addressDetails = this.viewingAddress
		this.openModal(content)
	}

	createAddressBookEntryPreSetup(event, modal) {

		this.addressDetails['id'] = event['id']
		this.addressDetails['name'] = event['name']
		this.addressDetails['email'] = event['email']
		this.addressDetails['tel'] = event['tel']

		this.additionalAddress['address'] = this.viewingAddress
		this.additionalAddress['cryptoId'] = this.global.addressBookInfoCryptoView[0]['id']

		this.modalRef = this.modalService.open(modal, {backdrop:false})

	}

	cryptoAddState = true
	createAddressBookEntry(modal, state = true) {

		console.log(this.newAddressDetails)

		this.addressDetails = {
			id: 0,
			type: this.global.currentPortfolioId ? 3 : 1,
			groupIds: [],
			portfolioId: this.global.currentPortfolioId ? this.global.currentPortfolioId : null,
			portfolioName: this.global.currentPortfolio ? this.global.currentPortfolio.name : null
		}

		this.cryptoAddState = state

		if (this.newAddressDetails != null) {
			this.addressDetails['address'] = this.newAddressDetails['address']
			this.addressDetails['crypto'] = this.newAddressDetails
		}

		this.modalRef = this.modalService.open(modal, {size:'lg', backdrop:'static'})
	}

	resetAdding() {
		this.addressDetails = {
			id: 0,
			type: this.global.currentPortfolioId ? 3 : 1,
			groupIds: [],
			portfolioId: this.global.currentPortfolioId ? this.global.currentPortfolioId : null,
			portfolioName: this.global.currentPortfolio ? this.global.currentPortfolio.name : null
		}
		this.addressBookModState = 0
		this.addressValidation = null
	}

	openModal(content) {
		this.modalRef = this.modalService.open(content);
	}

	openAddGroupModal(content) {
		this.addressGroupDetails = {
			name: '',
			desc: '',
			members: [], //Address Ids
			type: this.global.currentPortfolioId ? 3 : 1,
			portfolioId: this.global.currentPortfolioId ? this.global.currentPortfolioId : null,
			portfolioName: this.global.currentPortfolio ? this.global.currentPortfolio.name : null
		}
		this.errorCode = 0
		this.modalRef = this.modalService.open(content, {backdrop:'static'});
	}

	closeSubAlert() {
		this.errorCode = 0
		this.currentAddressViewIndex = null
	}

	public isdropdownOpen = false
	dropdownOpenClose(state) {
		this.isdropdownOpen = state
	}

	public addingAdditionalAddress = false

	additionalAddressValidation = true

	addAddress() {

		this.addingAdditionalAddress = true

		if (this.validateAdditionalAddress()) {
			this.additionalAddress['id'] = this.addressDetails['id']
			this.global.httpPOST('add_address_current', this.additionalAddress).subscribe(response => {
				this.addingAdditionalAddress = false
				this.modalRef.close();
				if (response.status == true) {
					this.resetAddressAdding()
					this.global.addresses.forEach(element => {
						if (element.id == this.addressDetails['id']) {
							//console.log(response.data)
							element['allAddresses'].push(response.data)
						}
					});
					// console.log('emit')
					this.addressAdded.emit(true)
					this.closeModalUpdateContact()
				}

			}, error => {
				this.addingAdditionalAddress = false
			});
		}

	}

	resetAddressAdding() {
		this.additionalAddress['label'] = null
		this.additionalAddress['address'] = null
	}

	validateAdditionalAddress() {
		let status = true

		if (this.additionalAddress['address'] == null) {
			this.additionalAddressValidation = false
			status = false
		}

		return status
	}

	contacttoUpdateInfo = null
	updateContactStatus = 0
	modalRefUpdate = null

	updateAddressBookEntry(modal) {
		this.updateContactStatus = 0
		this.contacttoUpdateInfo = null
		this.modalRefUpdate = this.modalService.open(modal)
	}

	closeModalUpdateContact() {
		this.modalRefUpdate.close()
	}

	contactToUpdate(info) {
		// console.log(info)
		this.contacttoUpdateInfo = info
		this.updateContactStatus = 1
	}

	bulkActionError = null;
	changeBulkActions(state) {
		this.bulkActionError = state;
	}

	openFullModal(content) {
		this.modalRef = this.modalService.open(content, { windowClass: 'gr-modal-full', backdrop: false });
	}

	closeImportAddressModal() {
		this.global.getAllAddresses();
		this.importAddress = {
			uploadEvent: null,
			importState: 0,
			walletsImported: false,
			importFileTypeError: null,
			errorList: []
		}

		this.modalRef.close();
	}
	

	uploadFile = function (event) {
		this.importAddress.importFileTypeError = false
		this.importAddress.uploadEvent = event
	};

	uploadGroupFile = function (event) {
		this.importAddressGroup.importFileTypeError = false
		this.importAddressGroup.uploadEvent = event
	};


	importAddressBook() {
		if (this.importAddress.uploadEvent.target.files[0].type == 'text/csv' || this.importAddress.uploadEvent.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			|| this.importAddress.uploadEvent.target.files[0].type == 'application/vnd.ms-excel') {
			this.importAddress.importFileTypeError = false
			this.importAddress.importState = 1
			// console.log(this.importAddress.uploadEvent.target.files[0].name)

			const data = new FormData();
			const files = this.importAddress.uploadEvent.target.files;
			this.global.$.each(files, function (key, value) {
				data.append(key, value);
			});

			data.append('dest', 'address_import');
			data.append('variable', "address_import");

			const tempObj = this;
			this.global.$.ajax({
				xhr() {
					const xhr = new XMLHttpRequest();
					return xhr;
				},
				url: this.global.API_URL + 'upload_file',
				headers: {
					'Authorization': 'Bearer ' + this.global.authService.TOKENS.access_token,
				},
				type: 'POST',
				data,
				cache: false,
				dataType: 'json',
				processData: false, // Don't process the files
				contentType: false, // Set content type to false as jQuery will tell the server its a query string requeste
				success(data: any, textStatus: any, jqXHR: any) {
					let filename = data.fileNameWithExt;
					tempObj.importAddress.importState = 2
					tempObj.global.httpPOST('import_address_book', {
						fileName: filename,
						portfolioId: tempObj.global.currentPortfolioId,
						originalFileName: tempObj.importAddress.uploadEvent.target.files[0].name
					})
						.subscribe(response => { //Fail with errors
							// console.log(response);
							if(!response.status && response.data && response.data.length > 0){
								tempObj.importAddress.errorList = response.data;
								tempObj.importAddress.importState = 4
							} else{ //Success
								tempObj.importAddress.importState = 3;
								this.global.getAllAddresses();
							}
						}, error => {

							tempObj.importAddress.importState = 4
						});
				},
				error(jqXHR, textStatus, errorThrown) {
					// console.log(errorThrown)
					// console.log('hitttt=================')
					// console.log(jqXHR)
					tempObj.importAddress.importState = 4
				}
			})
		} else {
			this.importAddress.importFileTypeError = true
		}
	}



	loadImportHistory(fresh = true){
		this.dataLoading = true
		let skipcount = 0;

		if(this.importHistory !=  null && fresh) {
			skipcount = this.importHistory.length
		}

		this.global.httpGET('get_import_history_address_book', {skip:skipcount }).subscribe(response => {

			this.dataLoading = false
			if(response.status==true){

				this.showLoadMore = true
				if(response.data.length < this.paginatecount){
					this.showLoadMore = false
				}

				if(this.importHistory ==  null){
					this.importHistory = response.data
				} else {
					if(response.data.length == 0){
						this.showLoadMore = false
					} else {
						response.data.forEach(element => {
							this.importHistory.push(element)
						});
					}
					
				}
				
			} else {
			}
		}, error => {
			this.dataLoading = false
		});
	}

	doingAddressBookExport = 0
	doAddressBookExport(){
		this.doingAddressBookExport = 1
		this.global.httpPOST('export_history_address_book', {}).subscribe(response => {

			this.doingAddressBookExport = 2
			if(response.status==true){
				
			} else {
			}
		}, error => {
			this.doingAddressBookExport = 3
		});
	}

	loadExportHistory(fresh = true){
		this.dataLoading = true
		let skipcount = 0;

		// if(this.exportHistory !=  null && fresh) {
		// 	skipcount = this.exportHistory.length
		// }

		this.global.httpGET('export_history_address_book', { skip:skipcount }).subscribe(response => {

			this.dataLoading = false
			if(response.status==true){
				this.exportHistory = response.data
				// this.showLoadMore = true
				// if(response.data.length < this.paginatecount){
				// 	this.showLoadMore = false
				// }

				// if(this.exportHistory ==  null){
				// 	this.exportHistory = response.data
				// } else {
				// 	if(response.data.length == 0){
				// 		this.showLoadMore = false
				// 	} else {
				// 		response.data.forEach(element => {
				// 			this.exportHistory.push(element)
				// 		});
				// 	}
					
				// }
				
			} else {
			}
		}, error => {
			this.dataLoading = false
		});
	}


	currentRollbackLot = null;
	triggerRollBack(modal, importLot){
		this.rollbackState = 0;
		// console.log('hit')
		// console.log(importLot)
		this.currentRollbackLot = importLot
		this.modalRef = this.modalService.open(modal, importLot);

	}

	confirmRollBack(){
		this.rollbackState = 1
		this.global.httpGET('addressbook_import_history_rollback', {batchId:this.currentRollbackLot.id}).subscribe(response => {
			this.rollbackState = 2
			this.importHistory = null
			this.loadImportHistory();
			this.global.getAllAddresses();
		}, error => {
			this.rollbackState = 3
		});
	}


	getAddressGroups() {
		let getData = null
		if(this.global.currentPortfolioId) {
			getData = {
				portfolioId: this.global.currentPortfolioId
			}
		}

		this.global.httpGET('get_address_groups', getData).subscribe(response => {
			this.addressGroups = response.data
			this.global.addressGroups = response.data
		}, error => {
		});
	}
	
	getUserPortfolios() {
		console.log('User Entities:');
		console.log(this.global.portfolios)
		
		if(this.global.portfolios == null) {
			this.coinService.getPortfolios(false, null, false, false)
		}
	}


	createAddressGroup() {

		this.dataloading = true
		if (this.addressGroupDetails.name == '' || this.addressGroupDetails.name == null || this.addressGroupDetails.name == undefined) {
			this.errorCode = 1
			this.dataloading = false
			return
		}

		this.global.httpPOST('add_address_group', {
			groupName:		this.addressGroupDetails.name, 
			groupDesc:		this.addressGroupDetails.desc,
			groupMembers:	this.addressGroupDetails.members,
			groupScope:		this.addressGroupDetails.type,
			portfolioId:	this.addressGroupDetails.portfolioId
		}).subscribe(response => {
			this.closeModal()
			this.getAddressGroups()
			this.dataloading = false
		}, error => {
			this.closeModal()
			this.getAddressGroups()
			this.dataloading = false
		});
		

	}


	selectGroupAddress(id){
		let arrIndex = this.addressGroupDetails['members'].indexOf(id)
		if (arrIndex !== -1) {
			this.addressGroupDetails['members'].splice(arrIndex, 1)
		} else {
			this.addressGroupDetails['members'].push(id)
		}

	}

	assignGroupToAddress(id, index, addrId){
		// console.log(id)
		// console.log(index)
		// console.log(addrId)
		// console.log(this.global.addressGroups[index].members)
		if (this.addressDetails.groupIds == null || this.addressDetails.groupIds == undefined) {
			this.addressDetails.groupIds = []
		}
		if (index == null) {
			return
		} else {
			let arrIndex = this.addressDetails.groupIds.indexOf(id)
			let included = this.global.addressGroups[index].members.indexOf(addrId)
			if (arrIndex == -1) {
				this.addressDetails.groupIds.push(id)
				if (included == -1) {
					this.global.addressGroups[index].members.push(addrId)
				}
			} else {
				this.addressDetails.groupIds.splice(arrIndex, 1)
				this.global.addressGroups[index].members.splice(this.global.addressGroups[index].members.indexOf(addrId), 1)
			}
			this.searchResults = this.global.addressGroups //This is requried to not reset teh entire list when selecting a group with group search active.
			this.addressGroups = this.global.addressGroups
			if(this.groupSearchValue != null || this.groupSearchValue != ""){
				this.applyQuickSearchGroups()
			}
		}
		// console.log(this.addressDetails)
	}

	isAddressInGroup(id){
		//console.log(id)
		this.global.addressGroups.forEach(group => {
			if (group['id'] == id) {
				let arrIndex = group['members'].indexOf(this.addressDetails['id'])
				if (arrIndex !== -1) {
					if(!this.addressDetails.groupIds.includes(id)) {
						this.addressDetails.groupIds.push(id)
					}
					return true
				} else {
					return false
				}
			}
		});
		return false
	}

	loadGroupIds() {
		let groupIds = []
		// console.log('Address Groups!')
		// console.log(this.global.addressGroups)
		if(this.global.addressGroups) {
			this.global.addressGroups.forEach(group => {
				group.members.forEach(member => {
					if (member == this.addressDetails.id) {
						groupIds.push(group.id)
					}
				});
			});
			return groupIds
		} else {
			return []
		}
	}

	isGroupAddressSelected(id){
		let arrIndex = this.addressGroupDetails['members'].indexOf(id)
		if (arrIndex !== -1) {
			return true
		} else {
			return false
		}
	}


	selectAddressPortfolio(id, name) {
		console.log(this.addingPortfolioToGroup)
		if (!this.addingPortfolioToGroup) {
			this.addressDetails['portfolioId'] = id
			this.addressDetails['portfolioName'] = name
		} else {
			this.addressGroupDetails['portfolioId'] = id
			this.addressGroupDetails['portfolioName'] = name
		}
		/*
		let arrIndex = this.addressDetails['portfolioIds'].indexOf(id)
		if (arrIndex !== -1) {
			this.addressDetails['portfolioIds'].splice(arrIndex, 1)
		} else {
			this.addressDetails['portfolioIds'].push(id)
		}
		*/
	}

	portfolioSelectorModalRef = null

	openPortfolioModal(modal, groupFlag = false){
		this.addingPortfolioToGroup = groupFlag 
		this.portfolioSelectorModalRef = this.modalService.open(modal, {size:'lg'})
	}

	addingPortfolioToGroup = false
	openLargeModal(modal, groupFlag = false){

		this.addingPortfolioToGroup = groupFlag 

		this.modalRef = this.modalService.open(modal, {size:'lg'})
	}

	closeModal(){
		this.modalRef.close()
	}



	
	foundResults = []
	foundResultsOnlyKey = []

	searchMode = false
	searchResults = this.global.addressGroups

	groupSearchValue = ""

	applyQuickSearchGroups(){

		if(this.groupSearchValue == null || this.groupSearchValue == ""){
			this.resetQuickSearchGroups()
			return
		}

		this.searchMode = true

		this.foundResults = []
		this.foundResultsOnlyKey = []

		const searchGroups = deepCopy(this.global.addressGroups);

		// console.log(searchGroups)

		for(var i = 0; i < searchGroups.length; i++) {

			searchGroups[i].nameBackup = searchGroups[i].group_name
			searchGroups[i].group_name = searchGroups[i].group_name.toUpperCase()

			if(searchGroups[i].group_name.search((this.groupSearchValue.toUpperCase())) !== -1){
				if(!this.foundResultsOnlyKey.includes(searchGroups[i].id)){
					this.foundResultsOnlyKey.push(searchGroups[i].id)
					this.foundResults.push(searchGroups[i])
				}
			}

			searchGroups[i].group_name = searchGroups[i].nameBackup
		}

		// console.log('Found Results!')
		// console.log(this.foundResults)
		this.searchResults = this.foundResults

	}

	resetQuickSearchGroups(){
		this.searchMode = false
		// console.log('reset search')
		this.groupSearchValue = null
		this.searchResults = this.global.addressGroups
		//this.groupVariableInCharge = this.global.addressGroups
	}




	foundResultsAddress = []
	foundResultsOnlyKeyAddress = []

	searchModeAddress = false

	addressSearchValue = ''

	applyQuickSearchAddress(){

		if(this.addressSearchValue == null || this.addressSearchValue == ""){
			this.resetQuickSearchAddress()
			return
		}

		this.searchModeAddress = true

		this.foundResults = []
		this.foundResultsOnlyKey = []

		const searchAddresss = deepCopy(this.global.addresses);

		// console.log(searchAddresss)

		for(var i = 0; i < searchAddresss.length; i++) {

			searchAddresss[i].nameBackup = searchAddresss[i].name
			searchAddresss[i].name = searchAddresss[i].name.toUpperCase()

			if(searchAddresss[i].name.search((this.addressSearchValue.toUpperCase())) !== -1){
				if(!this.foundResultsOnlyKey.includes(searchAddresss[i].id)){
					this.foundResultsOnlyKey.push(searchAddresss[i].id)
					this.foundResults.push(searchAddresss[i])
				}
			}

			searchAddresss[i].name = searchAddresss[i].nameBackup

		}

		// console.log('Found Results!')
		// console.log(this.foundResults)
		this.global.searchResultsAddress = this.foundResults

	}

	resetQuickSearchAddress(){
		this.searchMode = false
		// console.log('reset search')
		this.addressSearchValue = null
		this.global.searchResultsAddress = this.global.addresses
		//this.addressVariableInCharge = this.global.addressAddresss
	}
}
