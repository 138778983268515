import { NgModule } from '@angular/core';
import { PreloadAllModules, NoPreloading, RouterModule, Routes } from '@angular/router';
import { GlobalService } from '../_services/global.service';

// common components
// import { HotWalletsPageComponent } from '../_components_crypto_one/hot-wallets/hot-wallets-page.component';

// import { NewsPageComponent } from '../_components_a_common/news-page/news-page.component';

import { LogoutComponent } from '../_components_crypto_one/logout/logout.component'
import { LoginPageComponent } from '../_login-page/login-page.component';
import { ContactPageComponent } from '../_components_a_common/contact/contact.component';
import { AssetPageComponent } from '../_components_a_common/asset-info/asset-page.component';

import { ActivateComponent } from '../_components_a_common/activate/activate.component';
import { ExchangeAPICallback } from '../_components_crypto_one/exchange-api-callback/exchanges-api-callback.component';
import { QBCallback } from '../_components_crypto_one/qb-callback/qb-callback.component';

// import { PortfolioPageComponent } from '../_component_portfolios/_portfolio-page/portfolio-page.component';

// import { APITestPageComponent } from '../_components_crypto_one/apitest/apitest.component';
import { NotFoundComponent } from '../_components_a_common/404-not-found/404-not-found.component';
import { InvoicePubPageComponent } from '../_payments_app/invoice-pub-page/invoice-pub-page.component';

// import { MainDashboardPageComponent } from '../_components_crypto_one/main-dashboard-page/main-dashboard-page.component';
import { XeroCallbackComponent } from 'app/_components_crypto_one/xero-callback/xero-callback.component';
import { XeroLoginComponent } from 'app/_components_crypto_one/xero-login/xero-login.component';
import { ONSCallbackComponent } from 'app/_components_crypto_one/ons-callback/ons-callback.component';
import { PennylaneCallbackComponent } from 'app/_components_crypto_one/pennylane-callback/pennylane-callback/pennylane-callback.component';


const routes: Routes = [

    // {
    //     path: '',
    //     loadChildren: '../_components_dashboard/components-dashboard.module#DashboardModule'
    // },
    // {
    //     path: 'dashboard',
    //     loadChildren: '../_components_dashboard/components-dashboard.module#DashboardModule'
    // },
    // {
    //     path: 'portfolio',
    //     loadChildren: '../_components_crypto_one/components-crypto-one.module#CryptoOneModule'
    // },
    {
        path: 'workspace',
        loadChildren: '../_components_crypto_one/components-crypto-one.module#CryptoOneModule'
    },
    {
        path: 'entity',
        loadChildren: '../_components_crypto_one/components-crypto-one.module#CryptoOneModule'
    },
    {
        path: 'settings',
        loadChildren: '../_components_settings/components-settings.module#SettingsModule'
    },
    {
        path: 'address-book',
        loadChildren: '../_component_address_book/components-address-book.module#AddressBookModule'
    },
    {
        path: 'company',
        loadChildren: '../_component_organization/components-organization.module#OrganizationModule'
    },
    {
        path: 'library',
        loadChildren: '../_component_library/components-library.module#LibraryModule'
    },
    // {
    //     path: 'explore',
    //     loadChildren: '../_component_explore/components-explore.module#ExploreModule'
    // },
    // {
    //     path: 'billing',
    //     loadChildren: '../_component_billing/components-billing.module#BillingModule'
    // },
    // {
    //     path: 'pricing',
    //     loadChildren: '../_components_pricing/component-pricing.module#PricingModule'
    // },
    {
        path: 'register_admin_custom_free_acc',
        loadChildren: '../_components_register/components-register.module#RegisterModule'
    },
    {
        path: 'register_invite_only',
        loadChildren: '../_components_register/components-register.module#RegisterModule'
    },
    // {
    //     path: 'portfolios',
    //     loadChildren: '../_component_portfolios/portfolios.module#PortfoliosModule'
    // },
    {
        path: 'entities',
        loadChildren: '../_component_portfolios/portfolios.module#PortfoliosModule'
    },
    {
        path: '',
        component: LoginPageComponent,
        data: { divState: 0 }
    },
    {
        path: 'login',
        component: LoginPageComponent,
        data: { divState: 0 }
    },
    {
        path: 'register',
        component: LoginPageComponent,
        data: { divState: 0 }
    },
    {
        path: 'coin/:identifier',
        component: AssetPageComponent
    },
    {
        path: 'view-invoice/:invoiceId',
        component: InvoicePubPageComponent
    },
    {
        path: 'activate/:key',
        component: ActivateComponent
    },
    {
        path: 'login',
        component: LoginPageComponent,
        data: { divState: 0 }
    },
    {
        path: 'recover',
        component: LoginPageComponent,
        data: { divState: 3 }
    },{
        path: 'reset-password/:key',
        component: LoginPageComponent,
        data: { divState: 4 }
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    // {
    //     path: 'wallets',
    //     component: HotWalletsPageComponent
    // },
    // {
    //     path: 'news',
    //     component: NewsPageComponent
    // },
    {
        path: 'contact',
        component: ContactPageComponent
    },
    // {
    //     path: 'address-book',
    //     component: AddressBookPageComponent,
    //     canActivate: [GlobalService]
    // },
    {
        path: ':exchange/callback',
        component: ExchangeAPICallback,
        canActivate: [GlobalService]
    },
    {
        path: 'qb_callback',
        component: QBCallback,
        canActivate: [GlobalService]
    },
    {
        path: 'xero_callback',
        component: XeroCallbackComponent,
        //canActivate: [GlobalService]
    },
    {
        path: 'xero-signup',
        component: XeroLoginComponent,
        //canActivate: [GlobalService]
    },
    {
        path: 'ons_callback',
        component: ONSCallbackComponent,
        canActivate: [GlobalService]
    },

    {
        path: 'pl_callback',
        component: PennylaneCallbackComponent,
        canActivate: [GlobalService]
    },

    //PennylaneCallbackComponent
    // {
    //     path: 'test/ASDJALKSLAKALSDJ',
    //     component: APITestPageComponent,
    //     canActivate: [GlobalService],
    // },
    {
        path: '**',
        component: NotFoundComponent,
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: false, preloadingStrategy:NoPreloading})
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
