import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/_services/global.service';
import { ActivatedRoute, Router, Params, RouterStateSnapshot} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CoinService } from 'app/_services/coin.service';

@Component({
  selector: 'app-ons-callback',
  templateUrl: './ons-callback.component.html'
})
export class ONSCallbackComponent implements OnInit {

  public status:number = 0;

	constructor(public global: GlobalService, public modalService: NgbModal, public coinService:CoinService, private router:Router, public route:ActivatedRoute) {
	}

	ngOnInit() : void {
		if(this.global.isAuthenticated()){

		this.route.queryParams.subscribe(params => {
		this.getCode(params)
		});
		} else {
			this.global._cookieService.delete('oauth2Data', '/')
			this.global.logoutFinishStage();
		}


	}

	getCode(params:any){
		
		this.status = 1;
		let portfolioId = this.global._cookieService.get('current_portfolioId');
		console.log(portfolioId)

		this.global.httpPOST('ons_v_code',{
			'portfolioId': portfolioId,
			'code' : params.code
		}).subscribe( response=>{
			this.global.connectionDisplay = 6
			if(response.status){
			this.status = 2;
			
			setTimeout( () => { 
				this.router.navigate(['entity', portfolioId, 'connections']);
			}, 500);
			
			}else{
			this.status = 3;
			setTimeout( () => { 
				this.router.navigate(['entity', portfolioId, 'connections']);
			}, 500);
			}

		}, error=>{
			console.error(error);
			this.status = 3;
		});

	}


}
