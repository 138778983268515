import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation'

import { GlobalService } from '../_services/global.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {BsDatepickerModule, BsDropdownModule} from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome'
import {JsonToTableComponent} from "../_components_a_common/Json-to-table/json-to-table/json-to-table.component";
import { NgxCaptchaModule } from 'ngx-captcha';
// import { ParticlesModule } from 'angular-particle';
import { MatTableModule } from '@angular/material'
import { MatSortModule } from '@angular/material'
import { SidebarModule } from 'ng-sidebar';

import { FooterMiniComponent } from '../_components_a_common/footer-mini/footer-mini.component';
import { SideNavBarComponent } from '../_components_a_common/side-nav-bar/side-nav-bar.component';
import { HeaderGuestComponent } from '../_components_a_common/header-guest/header-guest.component';
import { FooterComponent } from '../_components_a_common/footer/footer.component';
import { BackDropComponent } from './back-drop/back-drop.component';

import { TransactionsFilterComponent } from 'app/_components_a_common/transactions-filter/transactions-filter.component'
import { ConnectionGroupQuickFilterComponent } from 'app/_components_a_common/connection-group-quick-filter/connection-group-quick-filter.component';
import { AddressGroupQuickFilterComponent } from 'app/_components_a_common/address-group-quick-filter/address-group-quick-filter.component';
import { FavoriteFilterComponent } from '../_components_a_common/favorite-filter/favorite-filter.component';

import { ComponentModelsComponent } from '../_components_a_common/system-message/maintenance/maintenance.component';

import { TagInfoComponent } from '../_components_a_common/tag-info/tag-info.component';

import { ActivateComponent } from '../_components_a_common/activate/activate.component';
import { AddressLinkComponent } from '../_components_a_common/address-link/address-link.component';
// import { NewsPageComponent } from '../_components_a_common/news-page/news-page.component';
// import { CoinListComponent } from '../_components_a_common/data-info/coin-list/coin-list.component';
// // import { PriceChartComponent } from '../_components_a_common/data-info/price-chart/price-chart.component';
// import { MarketSubnavComponent } from '../_components_a_common/data-info/market-subnav/market-subnav.component';
// import { CoinPageComponent } from '../_components_a_common/data-info/coin-page/coin-page.component';
import { AssetPageComponent } from '../_components_a_common/asset-info/asset-page.component';
import { ContactPageComponent } from '../_components_a_common/contact/contact.component';

import { CoinSearchComponent } from '../_components_a_common/coin-search/coin-search.component';
import { ChooseConnectionComponent } from '../_components_a_common/choose-connection/choose-connection.component';
import { InvoiceStatusComponent } from '../_components_a_common/invoice-status/invoice-status.component';
import { BillStatusComponent } from '../_components_a_common/bill-status/bill-status.component';
import { FilterViewComponent } from '../_components_a_common/filter-view/filter-view.component';
import { SyncStatusComponent } from '../_components_a_common/sync-status/sync-status.component';
import { AssetValueDisplayComponent } from '../_components_a_common/asset-value-display/asset-value-display.component';

import { UpgradePlanComponent } from '../_components_a_common/upgrade-plan/upgrade-plan.component';
import { UpgradePlanEPComponent } from '../_components_a_common/upgrade-plan-ep/upgrade-plan-ep.component';
import { InvoiceViewComponent } from '../_payments_app/invoices-page/invoice-view/invoice-view.component';
import { LogoutComponent } from '../_components_crypto_one/logout/logout.component'

// payments
import { RePaymentComponent } from '../_components_a_common/payment/re-payment/re-payment.component';
import { PayProcessingModalComponent } from '../_components_a_common/payment/pay-processing-modal/pay-processing-modal.component';

import { LabelPickerComponent } from '../_components_a_common//label-picker/label-picker.component';

import { AddressListComponent } from '../_components_a_common/address-list/address-list.component'
import { CreateContactComponent } from '../_components_a_common/create-contact/create-contact.component'
import { AddressBookViewComponent } from '../_components_a_common/address-book-view/address-book-view.component'
import { AddressViewInfoComponent } from '../_components_a_common/address-book-view/address-view-info/address-view-info.component'
import { AddressContactInfoComponent } from '../_components_a_common/address-contact-info/address-contact-info.component'
import { NotesInfoComponent } from '../_components_a_common/notes-info/notes-info.component'

import { LayoutComponent } from '../_components_a_common/settings/layout/layout.component'
import { InfoModalComponent } from '../_components_a_common/info-modal/info-modal.component';

import { OnboardingComponent } from '../_components_a_common/onboarding/onboarding.component';
import { OnboardingContentComponent } from '../_components_a_common/onboarding/onboarding-content/onboarding-content.component'

import { environment } from '../../environments/environment';
import { SocialLoginModule, GoogleLoginProvider, AuthServiceConfig} from 'angularx-social-login';
import { TxColumnsSelect } from '../_components_a_common/tx-columns-select/tx-columns-select.component';
import { SelectConnections } from '../_components_a_common/select-connections/select-connections.component';
import { ErpState } from '../_components_a_common/erp-state-component/erp-state.component';
import { DatePeriodView }   from '../_components_a_common/date-period-view/date-period-view.component';
import { SpreadSheetComponent } from './spread-sheet/spread-sheet.component';
import { TbRowComponent } from './spread-sheet/tb-row/tb-row.component';
import { TbCellComponent } from './spread-sheet/tb-cell/tb-cell.component';
import { CellinputComponent } from './spread-sheet/cellinput/cellinput.component';
import { ToolTipBhDirective } from './spread-sheet/tool-tip-bh.directive';

import { HeaderPortfolioComponent } from '../_components_a_common/header-portfolio/header-portfolio.component';

import { PortfolioHistoryPageComponent } from '../_components_a_common/portfolio-history/portfolio-history-page.component';
import { CronJobHistoryPageComponent } from '../_components_a_common/portfolio-history/cron-job-history/cron-job-history.component';
import { PortfolioBalanceHistoryPageComponent } from '../_components_a_common/portfolio-history/portfolio-balance-history/portfolio-balance-history.component';
import { SummaryReportHistoryComponent } from '../_components_a_common/portfolio-history/summary-report-history/summary-report-history.component';
import { PortfolioErpHistoryComponent } from '../_components_a_common/portfolio-history/portfolio-erp-history/portfolio-erp-history.component';

import { ImportTxEditorComponent } from '../_components_a_common/import-tx-editor/import-tx-editor.component';

import { GlobalLoader } from '../_components_a_common/global-loader/global-loader.component';

import { ConnectionsExpandedViewComponent } from '../_components_a_common/connection-expanded-view/connections-expanded-view.component';
import { ReporExportHistoryComponent } from '../_components_a_common/report-export-history/report-export-history.component';

import { CwDatePickerComponent } from '../_components_a_common/cw-date-picker/cw-date-picker.component';
import { CwQtPickerComponent } from '../_components_a_common/cw-date-picker/cw-qt-picker/cw-qt-picker.component';
import { CwDateRangePickerComponent } from '../_components_a_common/cw-date-range-picker/cw-date-range-picker.component';

import { DeFiFullComponent } from '../_components_a_common/defi-full/defi-full.component'
import { DeFiPriceDisplayComponent} from '../_components_a_common/defi-full/defi-price-display/defi-price-display.component'

import { PortfolioHistoryAccessComponent  } from './portfolio-history-access/portfolio-history-access.component';
import { SearchDropDownComponent } from 'app/_components_crypto_one/search-drop-down/search-drop-down.component';


import { WorkflowTrackerComponent } from 'app/_components_a_common/workflow-tracker/workflow-tracker.component';
import {SelectDropdownComponent} from '../_components_crypto_one/select-dropdown/select-dropdown.component';
import {
  CancelSnapshotSyncComponent
} from './portfolio-history/cron-job-history/modals/cancel-snapshot-sync/cancel-snapshot-sync.component';
import {SelectOptionComponent} from '../_components_crypto_one/select-dropdown/select-option/select-option.component';

const config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.googleClientID)
    }
]);

export function provideConfig() {
    return config;
}

@NgModule({
  imports: [
    SocialLoginModule,
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    ChartsModule,
    NgxIntlTelInputModule,
    Angular2FontawesomeModule,
    InfiniteScrollModule,
    SidebarModule,
    // ParticlesModule,
    MatTableModule,
    MatSortModule,
    NgxCaptchaModule,
    SocialLoginModule,
    NgbModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ProgressbarModule.forRoot(),
    RouterModule.forChild([]),


    //
    // CommonModule,
    BsDatepickerModule.forRoot(),

],
  entryComponents: [
    CancelSnapshotSyncComponent
  ],
  declarations: [
    CancelSnapshotSyncComponent,
    JsonToTableComponent,

    CwDatePickerComponent,
    CwQtPickerComponent,
    CwDateRangePickerComponent,
    DeFiFullComponent,
    DeFiPriceDisplayComponent,

    PortfolioHistoryAccessComponent,
    WorkflowTrackerComponent,

    ConnectionsExpandedViewComponent,
    ReporExportHistoryComponent,
    SideNavBarComponent,
    FooterMiniComponent,
    ComponentModelsComponent,
    TagInfoComponent,
    TransactionsFilterComponent,
    ConnectionGroupQuickFilterComponent,
    AddressGroupQuickFilterComponent,
    FavoriteFilterComponent,
    HeaderGuestComponent,
    // CoinListComponent,
    // // PriceChartComponent,
    // MarketSubnavComponent,
    // CoinPageComponent,
    AssetPageComponent,
    FooterComponent,
    RePaymentComponent,
    PayProcessingModalComponent,
    CoinSearchComponent,
    ChooseConnectionComponent,
    InvoiceStatusComponent,
    BillStatusComponent,
    FilterViewComponent,
    SyncStatusComponent,
    AssetValueDisplayComponent,
    UpgradePlanComponent,
    UpgradePlanEPComponent,
    InvoiceViewComponent,
    LogoutComponent,
    // NewsPageComponent,
    ActivateComponent,
    AddressLinkComponent,
    ContactPageComponent,
    AddressListComponent,
    CreateContactComponent,
    AddressBookViewComponent,
    AddressViewInfoComponent,
    AddressContactInfoComponent,
    NotesInfoComponent,
    LayoutComponent,
    OnboardingComponent,
    OnboardingContentComponent,
    InfoModalComponent,
    TxColumnsSelect,
    SelectConnections,
    ErpState,
    DatePeriodView,
    BackDropComponent,
    SpreadSheetComponent,
    TbRowComponent,
    HeaderPortfolioComponent,
    TbCellComponent,
    CellinputComponent,
    ToolTipBhDirective,

    PortfolioHistoryPageComponent,
    CronJobHistoryPageComponent,
    PortfolioBalanceHistoryPageComponent,
    SummaryReportHistoryComponent,
    PortfolioErpHistoryComponent,

    LabelPickerComponent,

    ImportTxEditorComponent,

    GlobalLoader,
    SearchDropDownComponent,
    SelectDropdownComponent,
    SelectOptionComponent,
    // ConnectionGroupQuickFilterComponent,
  ],
  providers: [
    // CoinPageComponent,
    // // PriceChartComponent,
    CronJobHistoryPageComponent,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  exports: [
    CommonModule,
    CwDatePickerComponent,
    CwQtPickerComponent,
    CwDateRangePickerComponent,
    DeFiFullComponent,
    DeFiPriceDisplayComponent,
    PortfolioHistoryAccessComponent,
    WorkflowTrackerComponent,
    FooterMiniComponent,
    ReporExportHistoryComponent,
    ComponentModelsComponent,
    TagInfoComponent,
    TransactionsFilterComponent,
    ConnectionGroupQuickFilterComponent,
    AddressGroupQuickFilterComponent,
    FavoriteFilterComponent,
    ConnectionsExpandedViewComponent,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    ChartsModule,
    NgxIntlTelInputModule,
    Angular2FontawesomeModule,
    JsonToTableComponent,
    InfiniteScrollModule,
    SidebarModule,
    // ParticlesModule,
    MatTableModule,
    MatSortModule,
    NgxCaptchaModule,
    SocialLoginModule,

    HeaderGuestComponent,
    FooterComponent,
    // CoinListComponent,
    // PriceChartComponent,
    // MarketSubnavComponent,
    // CoinPageComponent,
    AssetPageComponent,

    SideNavBarComponent,
    RePaymentComponent,
    PayProcessingModalComponent,

    OnboardingComponent,
    OnboardingContentComponent,

    HeaderPortfolioComponent,

    LabelPickerComponent,

    CoinSearchComponent,
    ChooseConnectionComponent,
    InvoiceStatusComponent,
    BillStatusComponent,
    FilterViewComponent,
    SyncStatusComponent,
    AssetValueDisplayComponent,
    UpgradePlanComponent,
    UpgradePlanEPComponent,
    InvoiceViewComponent,
    LogoutComponent,
    // NewsPageComponent,
    ActivateComponent,
    AddressLinkComponent,
    ContactPageComponent,
    AddressListComponent,
    CreateContactComponent,
    AddressBookViewComponent,
    AddressViewInfoComponent,
    AddressContactInfoComponent,
    NotesInfoComponent,
    LayoutComponent,
    InfoModalComponent,
    TxColumnsSelect,
    SelectConnections,
    ErpState,
    DatePeriodView,
    BackDropComponent,
    SpreadSheetComponent,

    PortfolioHistoryPageComponent,
    CronJobHistoryPageComponent,
    SummaryReportHistoryComponent,
    PortfolioErpHistoryComponent,

    ImportTxEditorComponent,

    GlobalLoader,
    SearchDropDownComponent,
    SelectDropdownComponent,
    SelectOptionComponent
    // CwDatePickerComponent,
    // CwQtPickerComponent,
  ]
})
export class CommonCAPModule { }
