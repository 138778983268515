<ul class="navbar-nav bg-white sidebar accordion position-fixed shadow-slight z2" style="background: #f7f8f9 !important"  id="accordionSidebar" >
	<div class="row">
		<div class="fullvh col pl-2 pt-2 border-left"  >
			
			<div class="row">
				<div class="col pr-0">
					<a class="div" [routerLink]="'/entities'">
						<img src="{{global.FE_IMG_URL}}img/logo/emblem.png" width="30" class="logo-img-header d-inline-block align-top ml-3 mt-2" alt="" >  
					</a>
					
				</div>
				<div class="col pr-0 ">
					<div ngbDropdown class="nav-item active nodropcaret pb-2" [class.d-inline-block]="global.sideBarCollapsed == 0">
						<div ngbDropdown placement="auto" ngbTooltip="User options">
							<div>
								<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen==1" (click)="global.dropdownOpenClose(0)"></div>
								<div class="media text-muted pt-3 pl-3 pb-2" [class.ml-1]="global.sideBarCollapsed==1" [class.upfront]="global.isdropdownOpen==1"  id="dropdownBasic2"  (click)="global.dropdownOpenClose(1)" ngbDropdownToggle style="margin-right: 0px !important;padding-left: 12px;">
									<span class="bd-placeholder-img mr-2 rounded pointer user-display" [class.w-42]="global.sideBarCollapsed==0"  [class.text-white]="global.isdropdownOpen==1" [class.upfront]="global.isdropdownOpen==1"  *ngIf=" global._userDetails['firstName'] != null && global._userDetails['lastName'] != null">
										{{ global._userDetails['first_name'][0].toUpperCase() }}{{ global._userDetails['last_name'][0].toUpperCase() }}
										<br/>
									</span>
									<span class="bd-placeholder-img mr-2 rounded pointer user-display"  [class.text-white]="global.isdropdownOpen==1" [class.upfront]="global.isdropdownOpen==1"  *ngIf=" global._userDetails['firstName'] == null || global._userDetails['lastName'] == null" >
										<i style="color: #fff !important;margin-right: 0px;" class="fa fa-user text-white" aria-hidden="true"></i>
										<br/>
									</span>
								</div>
								
							</div>
							<a class="nav-link" id="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1"  *ngIf=" global._userDetails['firstName']== null && global._userDetails['lastName'] == null" (click)="global.dropdownOpenClose(1)" ngbDropdownToggle>
								<i class="fa fa-sort-desc" aria-hidden="true"></i>
							</a>

							<div ngbDropdownMenu class="main-dropdown-menu" aria-labelledby="dropdownBasic2" [class.upfront]="global.isdropdownOpen==1">
								
								<div class=" dropdown-header noti-title pointer pb-0" >
									<span class="text-overflow m-0 text-center pointer" >
										<span class="pointer txtoverflow"  *ngIf=" global._userDetails['first_name'] != null || global._userDetails['last_name'] != null">
											<span *ngIf=" global._userDetails['firstName'] != null">{{ global._userDetails['first_name'] }}</span>
											<span *ngIf="global._userDetails['lastName'] != null"> {{ global._userDetails['last_name'] }}</span>
											<br/>
										</span>
										<small class="pointer">
											<b>{{ global._userDetails['email']}}</b>
										</small>
									</span>
								</div>
								<hr class="min-hr"/>
								<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)" [routerLink]="['/logout']">
									Logout
								</span>
								<hr class="min-hr"/>
								<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails!=null" (click)="global.dropdownOpenClose(0); coinService.gotoLink('company', global._companyDetails.id)">
									Organization
								</span>
								<span class="dropdown-item notify-item pointer" *ngIf="global._companyDetails==null" (click)="global.dropdownOpenClose(0); openModal(upgradePlanEPModal)">
									Organization
								</span>

								<!-- item-->
								<span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"(click)="coinService.gotoLink('settings');global.viewSettings = true">
									Settings
								</span>

								<!-- item-->
								<!-- <span class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)" [routerLink]="['/billing']" *ngIf="global._packageDetails != null && global._isOwner">
									Billings
								</span> -->
								<!-- <hr class="min-hr"/>
								<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"  (click)="openLayoutModal(layoutModal)">
									Layout & Style
								</a> -->
								<hr class="min-hr"/>
								<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)"  [routerLink]="['/library']">
									Data Library
								</a>
								


								<hr class="min-hr"/>
								<div class=" dropdown-header noti-title">

										<a href="https://cryptoworth.com/legal/terms" target="_blank" (click)="global.dropdownOpenClose(0)">
												<small>Terms</small>
										</a>&nbsp;&nbsp;

										<a href="https://cryptoworth.com/legal/privacy" target="_blank" (click)="global.dropdownOpenClose(0)">
											<small>Privacy</small>
										</a> &nbsp;&nbsp;

										<a target="_blank" href="https://cryptoworth.com/legal/disclaimer" >
											<small>Disclaimer</small>

										</a>

								</div>

								<hr style="margin: 0px"/>
								<div class=" dropdown-header noti-title">
									<small>
										<span *ngIf="global.socketConnectinStatus==1">
											<i class="fa fa-circle" style="color:#ff810e;"></i> Checking the server heart-beat.
										</span>
										<span *ngIf="global.socketConnectinStatus==2">
											<i class="fa fa-circle" style="color:#04c51d;"></i> Connected
										</span>
										<span *ngIf="global.socketConnectinStatus==3">
											<i class="fa fa-circle" style="color:#ef0000;"></i> Offline
										</span>
									</small>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<ul class="pl-2 pl-0 pr-0" >

				<div class="">
	
					<ul class="navbar-nav sidebars sb-footer-nav">
						<li class="nav-item dropdown active pointer nodropcaret p-0" (click)="addressBookVisit(addressViewModal);global.dropdownOpenClose(0)">
							<div class="media  pt-3 pl-3 pb-2"  id="dropdownBasic2"  style="margin-right: 0px !important;padding-left: 12px;">
								<span class="bd-placeholder-img mr-2 rounded pointer "  *ngIf=" global._userDetails['firstName'] != null && global._userDetails['lastName'] != null">
									<i class="fa fa-address-book-o icon-md 	" aria-hidden="true"></i>
								</span>
							</div>
						</li>
						<li class="nav-item dropdown active pointer nodropcaret p-0" >
							<div ngbDropdown class="media pt-1 pl-3 pb-2" placement="auto"  id="dropdownBasic2"  style="margin-right: 0px !important;padding-left: 12px;">
								<span ngbDropdownToggle class="bd-placeholder-img mr-2 rounded pointer "  *ngIf=" global._userDetails['firstName'] != null && global._userDetails['lastName'] != null">
									<i class="fa fa-question-circle icon-md" aria-hidden="true"></i> 
								</span>
								<div class="main-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2" >

									<a target="_blank" href="https://help.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
										<i class="mr-2 fa fa-question-circle" aria-hidden="true"></i> Help Center
									</a>
									<a target="_blank" href="https://www.youtube.com/@cryptoworthofficial" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
										<i class="mr-2 fa fa-youtube-play" aria-hidden="true"></i> Youtube Channel
									</a>
									<a target="_blank" href="https://blog.cryptoworth.com" class="dropdown-item notify-item pointer"  (click)="global.dropdownOpenClose(0);">
										<i class="mr-2 fa fa-rss-square" aria-hidden="true"></i> Blog
									</a>
									<!-- <a class="dropdown-item notify-item pointer" (click)="showOnboardingPopup(onboardingModal);global.dropdownOpenClose(0);">
										<i class="mr-2 fa fa-file-text" aria-hidden="true"></i>Onboarding Checklist
									</a> -->
									<a target="_blank" href="https://app.swaggerhub.com/apis-docs/Cryptoworth-Dev/Cryptoworth-Portfolio-Private-API/1.0.0#/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
										<i class="mr-2 fa fa-code" aria-hidden="true"></i> Developer Docs
									</a>

									<hr class="min-hr"/>
									<!-- item-->
									<a target="_blank" href="https://status.cryptoworth.app/" class="dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0)">
										<i class="mr-2 fa fa-wifi" aria-hidden="true" ></i> Platform Status
									</a>
									<hr class="min-hr"/>
									<a class="span dropdown-item notify-item pointer" (click)="global.dropdownOpenClose(0);openShareFeedbackModal(shareFeedbackModal)">
										<i class="mr-2 fa fa-paper-plane-o" aria-hidden="true"></i> Share feedback
									</a>
								</div>
							</div>
						</li>

					</ul>
	
				</div>


			</ul>
		</div>
	</div>


</ul>



<app-onboarding></app-onboarding>

<ng-template #layoutModal let-c="close" let-d="dismiss">

    <div class="modal-header bb-0">
        <!-- <h4 class="modal-title pull-left">Layout</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0">
		<app-layout></app-layout>
		<br/>
		<br/>
    </div>

</ng-template>

<ng-template #shareFeedbackModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Share Feedback</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
		<ng-container *ngIf="feedbackSent == 0">
			<p>Help us improve the platform by providing us a feedback or telling us what's on your wishlist.</p>
			<div class="form-group">
				<textarea type="text" maxlength="5000" [(ngModel)]="feedbackDescription" name="feedbackDescription"
					class="form-control" id="feedbackDescription" aria-describedby="Your feedbac" placeholder="Your feedback">
				</textarea>
				Have a specific issue? Contact our support team via our <a class="text-primary pointer" target="_blank" href="https://help.cryptoworth.com">Help Centre <i class="fa fa-external-link" aria-hidden="true"></i></a>.
			</div>
		</ng-container>
		<ng-container *ngIf="feedbackSent == 1">
			<div class="alert alert-success" role="alert">
				Thank you for your valuable feedback. Our team will get back to you shortly if required.
			</div>
		</ng-container>
		<ng-container *ngIf="feedbackSent == -1">
			<div class="alert alert-danger" role="alert">
				Something went wrong. Please contact the support team via the <a class="text-primary pointer" target="_blank" href="https://help.cryptoworth.com">Help Centre <i class="fa fa-external-link" aria-hidden="true"></i></a>.
			</div>
		</ng-container>
    </div>
	<div class="modal-footer" *ngIf="feedbackSent == 0">
		<button *ngIf="!sendingFeedback" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="sendFeedback()">
			Send Feedback
		</button>
		<button *ngIf="sendingFeedback" type="button" class="btn btn-primary btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif" /> Send Feedback
		</button>
	</div>
</ng-template>

<ng-template #upgradePlanEPModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Upgrade Plan</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-upgrade-plan-ep (click)="closeModal();"></app-upgrade-plan-ep>
    </div>
</ng-template>

<ng-template #addressViewModal let-c="close" let-d="dismiss">

    <div class="modal-header bb-0">
        <a  class="close pull-right py-2 pr-4" aria-label="Close" (click)="d('Cross click');closeModal()">
        	<span aria-hidden="true">&times;</span>
		</a>
    </div>
    <div class="modal-body p-0">
        <app-address-book-view [viewMode]="'mini'" (closeModalRequestEvent)="closeModal()"></app-address-book-view>
    </div>
</ng-template>

<ng-template #maintenanceModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title pull-left">Scheduled Maintenance</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Cryptoworth will perform a scheduled maintenance on <span class="text-bold">{{ global.appData.scheduledMaintenance.dateTime }}</span>.
		<br/><br/>
		The system will not be accessible within the time window. Sorry for any inconvenience caused.
    </div>
</ng-template>



