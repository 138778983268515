<div class="container" *ngIf="viewingAddress!=null">
	<div class="row pt-4">
		<div class="col-md-12">
			<h2>
				{{viewingAddress.name}}
				<div  ngbDropdown class="nav-item active pull-right nodropcaret"  placement="bottom-right" *ngIf="allowModify">

					<div class="modal-backdrop fade show" *ngIf="isdropdownOpen" (click)="dropdownOpenClose(false)"></div>


					<button *ngIf="viewingAddress.is_read_only != 1" class="btn btn-hr-min" [class.upfront]="isdropdownOpen==true" ngbDropdownToggle placement="bottom" (click)="dropdownOpenClose(true)"  ><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
					
					<ul class="fade-in" aria-labelledby="dropdownMenu1" ngbDropdownMenu style="top: 45px;left: 0px;" [class.upfront]="isdropdownOpen==true">
						<app-create-contact [viewingAddress]="viewingAddress" [createDisplayMode]="1" (click)="isdropdownOpen = false" (addressAdded)="addressNewAdd($event)"></app-create-contact>
						<hr class="m-0" />
						<li class="dropdown-item pointer" (click)="deleteAddress(deleteAddressModal, viewingAddress);dropdownOpenClose(false)">
							<span class="text-danger"><i class="mr-2 fa fa-trash text-danger"></i>Delete</span>
						</li>
					</ul>
					<button class="btn btn-hr-min ml-2"  (click)="global.addressBookListViewExpanded = true"  >
						<i class="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>

				<h4 class="text-bold pull-right" >

					<ng-container *ngIf="global._PRODUCT_ID == 'EP'">
						<i class="fa fa-user pt-2 mr-2" ngbTooltip="Personal address" placement="left" aria-hidden="true" *ngIf="viewingAddress.type==1"></i>
						<i class="fa fa-building pt-2 mr-2" ngbTooltip="Organizational address" placement="left" aria-hidden="true" *ngIf="viewingAddress.type==2"></i>
						<i class="fa fa-briefcase pt-2 mr-2" ngbTooltip="Entity address" placement="left" aria-hidden="true" *ngIf="viewingAddress.type==3"></i>
					</ng-container>
				</h4>
			</h2>
			<p>
				Email: 
				<span *ngIf="viewingAddress.email != null">{{viewingAddress.email}}</span>
				<span *ngIf="viewingAddress.email == null">-</span>
				<br/>
				Tel: 
				<span *ngIf="viewingAddress.tel != null">{{viewingAddress.tel}}</span>
				<span *ngIf="viewingAddress.tel == null">-</span>
			</p>
		</div>
	</div>

	<div class="row mt-4" *ngIf="viewingAddress.type==3 && viewingAddress.portfolio_id">
		<div class="col-md-12">
			<div>
				<span class="nav-link pl-0 ml-0"><b>Entity</b></span>
				<p class="small"> 
					This contact is only found within the following entity's address book.
				</p>
					<span>
						<i class="fa fa-briefcase pr-2"></i> {{viewingAddress.portfolioName }}
					</span>
			</div>
		</div>
	</div>


	<div class="row mt-4">
		<div class="col-md-12">
			<div>
				<span class="nav-link pl-0 ml-0"><b>Addresses</b></span>
				<p class="small"> 
					The addresses displayed below are not checked for correctness not have they been validated with the blockchains. Please do not send funds to addresses without double checking 
					the address. Cryptoworth is not liable for any loss of assets.
				</p>
				<div class="">
					<div class="card" *ngFor="let contactAddress of viewingAddress['allAddresses'];let i = index;">
						<div class="card-body">
							<div class="row">
								<div class="col mb-3">

									<label *ngIf="contactAddress['label'] != null"> {{ contactAddress['label'] }} </label>
									<label *ngIf="contactAddress['label'] == null"> - </label>

									<div  ngbDropdown class="nav-item active pull-right nodropcaret"  placement="bottom-right" *ngIf="allowModify">
					
										<div class="modal-backdrop fade show" *ngIf="dropDownOpenId == i" (click)="dropDownOpenId = -1"></div>
										<button class="btn btn-hr-min btn-sm" ngbDropdownToggle [class.upfront]="dropDownOpenId==i" placement="bottom" (click)="dropdownOpenCloseSpecific(i)"  ><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
										
										<ul class="fade-in" aria-labelledby="dropdownMenuAddress" ngbDropdownMenu style="top: 45px;left: 0px;" [class.upfront]="dropDownOpenId==i">
											<li class="dropdown-item pointer" (click)="editAddressLabelInit(contactAddress, i, editAddressLabelModal);dropDownOpenId = -1"><span><i class="mr-2 fa fa-pencil text-primary"></i>Edit label</span></li>
											<li class="dropdown-item pointer" (click)="removeAddressInit(contactAddress, i, removeAddressModal);dropDownOpenId = -1"><span class="text-danger"><i class="mr-2 fa fa-trash text-danger"></i>Delete</span></li>
										</ul>
									</div>

								</div>
							</div>

							<div class="input-group">
								<!-- <div class="input-group-prepend pointer" (click)="copyAddress(walletAddress)" ngbTooltip="Copied!" triggers="click:blur">
									<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{viewingAddress['crypto_id']['image']}}" width="17" height="17" alt=""> {{viewingAddress['crypto_id']['display_symbol']}} 
								</div> -->
								<div class="input-group-prepend" *ngIf="contactAddress['crypto_id'] != null">
									<span class="border btn btn-link disabled" id="inputGroup-sizing-default"> <img class="img-fluid" src="{{global.COIN_IMG_URL}}{{contactAddress['crypto_id']['image']}}?ver={{global.imageVer}}" width="17" height="17" alt=""> {{contactAddress['crypto_id']['display_symbol']}}  </span>
								  </div>
								<input type="text"  class="form-control address-view" value="{{contactAddress['address']}}" readonly>
								<div class="input-group-append pointer" (click)="global.copyToClipboard(contactAddress['address'])" ngbTooltip="Copied!" triggers="click:blur">
									<a class="btn btn-hr-min"><i class="fa fa-files-o" aria-hidden="true"></i></a>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="global.addressGroups.length > 0">
	<div class="row mt-4">
		<div class="col-md-7">
			<span class="nav-link pl-0 ml-0"><b>Groups</b></span>
			<ng-container *ngFor="let group of global.addressGroups; let i = index">
				<div *ngIf="group.members.includes(viewingAddress['id']) || group.members.includes(viewingAddress['address_id'])">
					<i class="fa fa-group pr-2"></i> {{ group.group_name }}
				</div>
			</ng-container>
		</div>
	</div>
	</ng-container>
	<div class="row mt-4">
		<div class="col-md-7">
			<div>
				<span class="nav-link pl-0 ml-0"><b>Notes</b></span>
				<ng-container >
					
					<span *ngIf="viewingAddress['notes'] != null">
					
						<br/>
						{{ viewingAddress['notes'] }}
					</span>
					<span *ngIf="viewingAddress['notes'] == null">
						-
					</span>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<div *ngIf="viewingAddress==null">

</div>

<ng-template #deleteAddressModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left">Delete Address</h4>
		<button type="button" class="close pull-right" 
				aria-label="Close" (click)="d('Cross click')">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<p> Are you sure you want to delete this address?
			</p>
		</div>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="!deletingAddress">
			<button type="button" class="btn btn-danger btn-lg " (click)="deleteAddressConfirm()">Delete</button>
		</ng-container>
		<ng-container *ngIf="deletingAddress">
			<button type="button" class="btn btn-danger btn-lg disabled" disabled><img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Delete
			</button>
		</ng-container>
	</div>
</ng-template>
<!-- end of removing address -->

<ng-template #removeAddressModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left">Remove Address</h4>
		<button type="button" class="close pull-right" 
				aria-label="Close" (click)="d('Cross click')">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<p> Are you sure you want to remove this address from the contact?
			</p>
			<p> {{addressOfInterest['address']}} </p>
		</div>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="!editingAddress">
			<button type="button" class="btn btn-danger btn-lg " (click)="confirmRemoveAddress()">Delete</button>
		</ng-container>

		<ng-container *ngIf="editingAddress">
			<button type="button" class="btn btn-danger btn-lg " disabled><img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Delete</button>
		</ng-container>
	</div>
</ng-template>

<ng-template #editAddressLabelModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left">Edit Contact Address</h4>
		<button type="button" class="close pull-right" 
				aria-label="Close" (click)="d('Cross click')">
		<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="sel1">Label</label>
			<input type="text" [(ngModel)]="editAddres['label']" name="addressName" class="form-control" id="addressName"  placeholder="Address label">
		</div>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="!editingAddress">
			<button type="button" class="btn btn-danger btn-lg " (click)="confirmEditContractAddress()">Edit</button>
		</ng-container>

		<ng-container *ngIf="editingAddress">
			<button type="button" class="btn btn-danger btn-lg " disabled><img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Edit</button>
		</ng-container>
	</div>
</ng-template>
<!-- end of removing address -->
