import { Injectable} from '@angular/core';
import { Http, } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common'
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../../environments/environment';
@Injectable()
export class AuthenticationService {

	public TOKENS = null;
	public API_URL
	public COIN_IMG_URL
	public IMG_URL
	public FE_IMG_URL
	public EXCHANGE_IMG_URL
	public GATEWAY_IMG_URL
	public HS_WALLET_IMG_URL
	public DEFI_IMG_URL
	public ENV_SET
	public CDN_IMG_URL = null
    public show500Notice = false

  	constructor(
		public _cookieService:CookieService,
		public http:Http, private route: ActivatedRoute, public router:Router) {

			//console.log("environment.title",environment.title);

			this.CDN_IMG_URL 		= 'https://cw-images-all.s3.us-east-2.amazonaws.com/'
			this.GATEWAY_IMG_URL 	= 'https://cw-images-all.s3.us-east-2.amazonaws.com/gateways/icons/'
			this.DEFI_IMG_URL 		= 'https://cw-images-all.s3.us-east-2.amazonaws.com/defi/'
			this.HS_WALLET_IMG_URL 	= 'https://cw-images-all.s3.us-east-2.amazonaws.com/sh_wallets/'
			this.EXCHANGE_IMG_URL 	= 'https://cw-images-all.s3.us-east-2.amazonaws.com/exchanges/'
			this.COIN_IMG_URL 		= 'https://cw-images-coins.s3.us-east-2.amazonaws.com/'
			this.IMG_URL 			= 'https://cw-images-all.s3.us-east-2.amazonaws.com/img_general/'
			this.FE_IMG_URL 		= 'https://cw-images-all.s3.us-east-2.amazonaws.com/img_fe/'

			if(window.location.host=='am.cryptoworth.app' || window.location.host=='cryptoworth.com' || window.location.host=='one.cryptoworth.app'){
				this.ENV_SET = 'prod'
				this.API_URL = 'https://api.cryptoworth.app/v1/'
			} else if(window.location.host=='staging.cryptoworth.app' || window.location.host=='staging.interplay.network'){
				this.ENV_SET = 'staging'
				this.API_URL = 'https://stagingapi.interplay.network/v1/'
			} else if(window.location.host=='staging-ca.interplay.network'){
				this.ENV_SET = 'staging'
				this.API_URL = 'https://staging-ca-api.interplay.network/v1/'
			} else if(window.location.host=='staging-reports.interplay.network'){
				this.ENV_SET = 'staging'
				this.API_URL = 'https://staging-reports-api.interplay.network/v1/'
			} else if(environment.title === "local"){
				this.ENV_SET = 'dev'
				this.API_URL = 'http://localhost:8000/v1/'
			} else {
				this.ENV_SET = 'dev'
				this.API_URL = 'http://localhost/cryptoworth/cwae/public/v1/'
			}

	}

	refreshTokens(): Observable<any> {

			return this.http.post(this.API_URL + 'refresh_token', {
				refreshToken:this.TOKENS['refresh_token']
			})


	}

	logout(){
		this._cookieService.deleteAll('/')
		this.router.navigate(['login']);
	}

}
